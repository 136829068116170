import { useState, useEffect, useCallback } from "react";

export default function Nav() {
  const [color, setColor] = useState(false);
  const COLOR_CHANGE_THRESHOLD = 1;

  const changeColor = useCallback(() => {
    const isScrolled = window.scrollY >= COLOR_CHANGE_THRESHOLD;
    if (isScrolled !== color) {
      setColor(isScrolled);
    }
  }, [color]);

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, [changeColor]);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={color ? "header header-bg" : "header"}>
      <div className="headerItems">
        <button onClick={() => scrollToSection("Top")}>top</button>
        <button onClick={() => scrollToSection("projekt")}>
          mine projekter
        </button>
        <button onClick={() => scrollToSection("omMig")}>om mig</button>
      </div>
    </div>
  );
}
