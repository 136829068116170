import Typewriter from "typewriter-effect";
import PostItem from "../components/postItem";
import { useState, useEffect, useRef } from "react";
import photo from "../om-mig-photo.jpg";
let iAm = [
  " a frontend developer",
  " a scout",
  "a multimediadesigner",
  "a nerd",
  "a boardgame enthusiast",
  "a webdeveloper",
  "a bouldering beginner",
];

export default function HomePage() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://wordpress.magnuskingo.dk/wp-json/wp/v2/posts?_embed"
      );
      const data = await response.json();
      console.log(data);
      setPosts(data);
    }
    getData();
  }, []);

  const [text, setText] = useState(false);
  const headerRef = useRef(null);
  useEffect(() => {
    const changeText = () => {
      let padding;
      if (window.innerWidth <= 768) {
        // 768px is a common breakpoint for mobile devices
        padding = window.innerHeight * 0.05; // Adjust this value as needed for mobile devices
      } else {
        padding = window.innerHeight * 0.2; // Original value for non-mobile devices
      }
      const TEXT_CHANGE_THRESHOLD = headerRef.current.offsetHeight - padding;
      if (window.scrollY >= TEXT_CHANGE_THRESHOLD && text !== true) {
        setText(true);
      }
      if (window.scrollY < TEXT_CHANGE_THRESHOLD && text !== false) {
        setText(false);
      }
    };
    window.addEventListener("scroll", changeText);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", changeText);
    };
  }, [text]);

  return (
    <section className="page" id="Top">
      <div className="heroimg">
        <div className="heroimgtop" ref={headerRef}>
          <div className={text ? "text" : "text fixed"}>
            <h1>Magnus Kingo Danielsen</h1>
            <div className="typeWriter">
              <p>I am </p>
              <Typewriter
                options={{
                  strings: iAm,
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                  delay: 50,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="portfolio" id="projekt">
        <h1>Mine projekter</h1>
        <div className="grid-container">
          {posts.map((post) => (
            <PostItem key={post.id} post={post} />
          ))}
        </div>
      </section>
      <div className="line" id="omMig"></div>
      <section className="om-mig">
        <div className="om-mig-tekst">
          <h1>Hvem er jeg?</h1>
          <p>
            Jeg en en frisk fyr på 23 år, der til dagligt studerer til
            webudvikler på erhversakadamiet i Århus. I min fritid er jeg meget
            intreserret indenfor teknologi og alt hvad det indebærer. Alt fra
            computere til IOT til 3D printere. Selvom jeg godt kan lide at side
            foran en computer så er jeg også et naturmenneske der nyder gåture i
            diverse omgivelser eller en tur i kajak. Dette kommer fra mine mere
            end 16 år som spejder hvor jeg både som spejder og spejderleder har
            været på adskillige ture, lejre og kurser både i Danmark men også i
            udlandet. Jeg er fornyligt begyndt på bouldering hvilket jeg syntes
            er en sjov og udfordrenede træning der passer godt ind i den måde
            jeg selv træner. Jeg elsker at hænge ud med mine venner og vi ender
            oftes med at sidde omkring et brætspil og spille mens vi snakker og
            hygger.
          </p>
          <h1>Hvorfor Frontend?</h1>
          <p>
            Siden gymnasiet hvor jeg for første gang dyppede føderne i html og
            css har jeg været facineret. Måden hvorpå koden man skriver skaber
            og ændre siden med det samme er fantastisk. Den visuelle feedback på
            ens kode har været med til at jeg blev fanget af det, og da jeg
            senere lærte andre sprog som javascript blev mulighederne bare
            uendelige. Jeg elsker at side og nørde med koden for at se dele af
            siden blive opbygget og hvordan det hele til sidst skaber en samlet
            helhed. Jeg har altid været en person der godt kan lide at lave
            noget og se resultatet af det, og det er lige præcis hvad jeg får ud
            af at lave hjemmesider. Efter at have færdiggjort min uddannelse som
            multimedie designer valgte jeg at læse videre som webudvikler for at
            få en dybere viden indenfor både frontend og backend og lærer mere
            omkring udvikling af hjemmesider og applikationer. Dette har jeg
            indtil videre gjort gennem en masse nye sprog og værkstøjer som jeg
            har lært at bruge og implementere i mine projekter.
          </p>
        </div>
        <div className="om-mig-img">
          <img src={photo} alt="billede af Magnus Kingo Danielsen"></img>
        </div>
      </section>
    </section>
  );
}
